/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState, Suspense} from "react";
import { NavLink, useLocation,useParams  } from "react-router-dom";
// import { useParams } from "react-router-dom";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
// import DefaultAuth from "layouts/auth/Default";
import DialogModal from "components/Modal/QuesntionAddModal";
import ResponseTable from "views/admin/dataTables/components/ResponseTBL";

// Assets
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons';

import MapComponent from "components/map/";



function Questionaire() {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();

  let { id } = useParams();
  // console.log(id);

  const [entryPage, setEntryPage] = useState(false);
 
  

  //Submit the form  -- CREATE RECORD --- 
  // const handleOnSubmit = (question) => {
  //   // console.log(question);
    
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer my-token',
  //         'My-Custom-Header': 'foobar'
  //     },
  //     body: JSON.stringify(question)
  //   };
  //   fetch('/questions', requestOptions)
  //       .then(response => JSON.stringify(response))
  //       .then(data => {
  //         data && toast({
  //           title: "Success!.",
  //           description: "Record created successfully.",
  //           status: "success",
  //           duration: 5000,
  //           isClosable: true
  //         });
  //       });
        
  // };

  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Box bg="white" w="100%" p={4} color="white">

      <Flex>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "1500px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          
            <React.Fragment>
              <DialogModal
                questionnaireId ={id}
              />
            </React.Fragment>

          <Flex align='center' mb='25px' w='100%'>
            <MapComponent url={'http://159.223.172.47/fetch-questionnaire-response/' + id}/>
          </Flex>
          
        </Flex>
      </Flex>
      {/* <Suspense>
        <ResponseTable questionId ={id} />
      </Suspense> */}
    </Box>
  );
}

export default Questionaire;
