
import React, {useState} from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from "@chakra-ui/react";

import {  AddIcon } from '@chakra-ui/icons';



const DialogModal = (props) => {
    const textColor = useColorModeValue("navy.700", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleHover = useColorModeValue(
        { bg: "gray.200" },
        { bg: "whiteAlpha.300" }
    );
    const googleActive = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const [questionCat, setBook] = useState({
      name: props.questionCat ? props.questionCat.name : '',
      description: props.questionCat ? props.questionCat.description : '',
    });
  
    const [errorMsg, setErrorMsg] = useState('');
    const { name, description } = questionCat;
  
    const handleOnSubmit = (event) => {
      event.preventDefault();
      const values = [name, description];
      let errorMsg = '';
  
      const allFieldsFilled = values.every((field) => {
        const value = `${field}`.trim();
        return value !== '' && value !== '0';
      });
  
      if (allFieldsFilled) {
        const questionCat = {
          id: uuidv4(),
          name,
          description,
        //date: new Date()
        };
        props.handleOnSubmit(questionCat);
        onClose();
      } else {
        errorMsg = 'Please fill out all the fields.';
      }
      setErrorMsg(errorMsg);
    };
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case 'quantity':
          if (value === '' || parseInt(value) === +value) {
            setBook((prevState) => ({
              ...prevState,
              [name]: value
            }));
          }
          break;
        case 'price':
          if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
            setBook((prevState) => ({
              ...prevState,
              [name]: value
            }));
          }
          break;
        default:
          setBook((prevState) => ({
            ...prevState,
            [name]: value
          }));
      }
    };

    
  const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <div className="main-form">
        <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color='green'
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={onOpen} >
            <Icon as={AddIcon} w='40px' h='20px' me='10px'/>
            Create Question Category
          </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Question Category</ModalHeader>
                    <ModalCloseButton />
                    {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                    <form onSubmit={handleOnSubmit}>
                        <ModalBody>
                            
                          <FormControl id="name" isRequired>
                              <FormLabel
                              display='flex'
                              ms='4px'
                              fontSize='sm'
                              fontWeight='500'
                              color={textColor}
                              mb='8px'>
                              Name
                              </FormLabel>
                              <Input
                              isRequired={true}
                              // variant='auth'
                              fontSize='sm'
                              ms={{ base: "0px", md: "0px" }}
                              type='text'
                              placeholder='General Data Collection'
                              mb='24px'
                              fontWeight='500'
                              size='lg'
                              onChange={handleInputChange}
                              name="name" />
                          </FormControl>
                          
                          <FormControl id="description">
                              <FormLabel
                              ms='4px'
                              fontSize='sm'
                              fontWeight='500'
                              color={textColor}
                              display='flex'>
                              Description<Text color={brandStars}></Text>
                              </FormLabel>
                              <InputGroup size='md'>
                              <Input
                                  fontSize='sm'
                                  placeholder='Optional Diescription'
                                  mb='24px'
                                  size='lg'
                                  name='description'
                                  onChange={handleInputChange}/>

                              </InputGroup>
                          </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                fontSize='sm'
                                variant='ghost'
                                colorScheme='green'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                type="submit">
                                Submit
                            </Button>

                        </ModalFooter>
                    </form>

                </ModalContent>

            </Modal>
        </div>
    )
}

export default DialogModal;

