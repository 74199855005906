import React, {useState} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sha1 from 'js-sha1';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    useToast
  } from "@chakra-ui/react";
import { register } from "../../../actions/auth";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const LoginButton = (props) => {
//   const { loginWithRedirect } = useAuth0();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );


  const [signUpForm, setsignUpForm] = useState({
    first_name: props.first_name ? props.first_name : '',
    last_name: props.last_name ? props.last_name : '',
    email: props.email ? props.email : '',
    password: props.password ? props.password : ''
    // password: sha1('props.password') ? sha1('props.password') : ''
  });

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();
  

  let history = useHistory();

  const [errorMsg, setErrorMsg] = useState('');
  const { first_name, last_name, email, password} = signUpForm;

  const toast = useToast();

  const handleOnSubmit = (event) => {
    // alert('here!');
    event.preventDefault();
    setSuccessful(false);
    // console.log(signUpForm);
    const values = [ first_name, last_name, email, password];
    let errorMsg = '';

    const allFieldsFilled = values.every((field) => {
    //console.log(field);

      const value = `${field}`.trim();
      return value !== '' && value !== '0';
    });
    if (allFieldsFilled) {
      const signUpForm = {
        last_name,
        first_name,
        email,
        password
      };
      //console.log(signUpForm);
      // props.handleOnSubmit(signUpForm);
      dispatch(register(last_name, first_name, email, password))
        .then(() => {
          setSuccessful(true);
          // history.push("/auth/sign-in");
          window.location.reload();
          toast({
            title: "Successful!.",
            description: "Record created successfully.",
            status: "successful",
            duration: 5000,
            isClosable: true
          });
        })
        .catch(() => {
          setSuccessful(false);
        });
    //   onClose();
    } else {
      errorMsg = 'Please fill out all the fields.';
    }
    setErrorMsg(errorMsg);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setsignUpForm(questionOption => ({ ...questionOption, [name]: value }));
    };


     const handleInputChange = (event) => {
      const { name, value } = event.target;
      //console.log("Element name is" + name + ".");
      switch (name) {
        case 'password':
          // console.log("b4 if password value: " + value + ".")
          // console.log("b4 if password value number: " + value.length + ".")
          //if (value === '' || parseInt(value) === +value) {
          if (value.length > 7) {
           // console.log("after if password value: " + value + ".")
            setsignUpForm((prevState) => ({
              ...prevState,
              [name]: value.trim()
              // [name]: sha1(value)
            }));
          }
          break;
          // case 'active':
          //   if(active){
          //       setsignUpForm((prevState) => ({
          //       ...prevState,
          //       [name]: false
          //     }));
          //   }else{
          //       setsignUpForm((prevState) => ({
          //       ...prevState,
          //       [name]: true
          //     }));
          //   }
            
          // break;
          // case 'repeat_questions':
          //   if(repeat_questions){
          //       setsignUpForm((prevState) => ({
          //       ...prevState,
          //       [name]: false
          //     }));
          //   }else{
          //       setsignUpForm((prevState) => ({
          //       ...prevState,
          //       [name]: true
          //     }));
          //   }
          // break;
        // case 'price':
        //   if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
        //     setQuestionGroup((prevState) => ({
        //       ...prevState,
        //       [name]: value
        //     }));
        //   }
        //   break;
        default:
          // console.log("default password value: " + value + ".")
          setsignUpForm((prevState) => ({
            ...prevState,
            [name]: value
          }));
      }
    };

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [show2, setShow2] = React.useState(false);
  const handleClick2 = () => setShow2(!show2);

  const [entryPage, setEntryPage] = useState(false);
  function doSetPage(){
    return setEntryPage(!entryPage);
  }

  return(
    // <Flex justifyContent='space-between' align='center' mb='24px'>
    //     <Button
    //         fontSize='sm'
    //         variant='brand'
    //         fontWeight='500'
    //         w='100%'
    //         h='50'
    //         mb='24px'
    //         onClick={() => loginWithRedirect()} >
    //         Log In
    //     </Button>
    // </Flex>
    <div className="main-form">
       
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                // mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'>
                <Box me='auto'>
                <Heading color={textColor} fontSize='36px' mb='10px'>
                    Sign Up
                </Heading>
                <Text
                    mb='36px'
                    ms='4px'
                    color={textColorSecondary}
                    fontWeight='400'
                    fontSize='md'>
                    Fill out form to sign up!
                </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}>
                {/* <Button
                    fontSize='sm'
                    me='0px'
                    mb='26px'
                    py='15px'
                    h='50px'
                    borderRadius='16px'
                    bg={googleBg}
                    color={googleText}
                    fontWeight='500'
                    _hover={googleHover}
                    _active={googleActive}
                    _focus={googleActive}>
                    <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
                    Sign in with Google
                </Button>
                <Flex align='center' mb='25px'>
                    <HSeparator />
                    <Text color='gray.400' mx='14px'>
                    or
                    </Text>
                    <HSeparator />
                </Flex>  */}
                

                    {/* <Login /> */}
                    <form onSubmit={handleOnSubmit}>
                        <span>{errorMsg && <p className="errorMsg">{errorMsg}</p>}</span>
                        <FormControl id="signupForm" isRequired>
                            
                        <FormControl id="first_name" isRequired>
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            display='flex'>
                            first name
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                placeholder='firstname'
                                mb='24px'
                                size='lg'
                                type='text'
                                name='first_name'
                                onChange={handleInputChange}
                                />
                            </InputGroup>
                        </FormControl>

                        <FormControl id="last_name" isRequired>
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            display='flex'>
                            last name
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                placeholder='last name'
                                mb='24px'
                                size='lg'
                                type='text'
                                name='last_name'
                                onChange={handleInputChange}
                                />
                            </InputGroup>
                        </FormControl>

                        <FormControl id="email" isRequired>
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            display='flex'>
                            email
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                placeholder='mail@simmmple.com'
                                mb='24px'
                                size='lg'
                                type='text'
                                name='email'
                                onChange={handleInputChange}
                                />
                            </InputGroup>
                        </FormControl>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Password
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input id="password"
                                isRequired={true}
                                fontSize='sm'
                                placeholder='Min. 8 characters'
                                mb='24px'
                                size='lg'
                                type={show ? "text" : "password"}
                                variant='auth'
                                onChange={handleInputChange}
                                name='password'
                            />
                            
                            <InputRightElement display='flex' alignItems='center' mt='4px'>
                                <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick}
                                />
                            </InputRightElement>
                            </InputGroup>

                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Retype Password
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input id="retype-password"
                                isRequired={true}
                                fontSize='sm'
                                placeholder='Min. 8 characters'
                                mb='24px'
                                size='lg'
                                type={show2 ? "text" : "password"}
                                variant='auth'
                                name='confirm-password'
                            />
                            
                            <InputRightElement display='flex' alignItems='center' mt='4px'>
                                <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick2}
                                />
                            </InputRightElement>
                            </InputGroup>

                            <Flex justifyContent='space-between' align='center' mb='24px'>
                            <FormControl display='flex' alignItems='center'>
                                <Checkbox
                                id='remember-login'
                                colorScheme='brandScheme'
                                me='10px'
                                />
                                <FormLabel
                                htmlFor='remember-login'
                                mb='0'
                                fontWeight='normal'
                                color={textColor}
                                fontSize='sm'>
                                Keep me logged in
                                </FormLabel>
                            </FormControl>
                            <NavLink to='/auth/forgot-password'>
                                <Text
                                color={textColorBrand}
                                fontSize='sm'
                                w='124px'
                                fontWeight='500'>
                                have an account?
                                </Text>
                            </NavLink>
                            </Flex>
                            <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                type="submit">
                                Sign Up
                            </Button>

                            {message && (
                              <div className="form-group">
                                <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                                  {message}
                                </div>
                              </div>
                            )}
                        </FormControl>
                    </form>
                </Flex>
            </Flex>
    </div>
  ); 
};

export default LoginButton;