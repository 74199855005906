import React, {useState, useEffect} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Flex,
    Select,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Text,
    InputGroup,
    IconButton,
    Tooltip,
    Alert,
    useColorModeValue,
    AlertIcon,
  } from "@chakra-ui/react";
import { EditIcon } from '@chakra-ui/icons';
import { useSelector } from "react-redux";

  
  type ModalPreviewProps = {
    validatorHandlers?: { (data: any): string | null }[];
    //submitHandler?: (data: any[]) => void;
    invalidConsignmentMsg?: string;
    data?: any[];
    testIdPrefix?: string;
    modalHeader: string;
    buttonIcon: EditIcon;
    tooltipLabel: string;
  };
  
  const ModalPreview = ({
    validatorHandlers,
    data,
    testIdPrefix,
    modalHeader,
    buttonIcon,
    tooltipLabel,
    submitHandler,
  }: ModalPreviewProps): JSX.Element => {
    // console.log(data);
    const textColor = useColorModeValue("navy.700", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");


    const { isOpen, onOpen, onClose } = useDisclosure();
    const [questionProp, setQuestionProp] = useState({
      question: data ? data.question : '',
      questionnaire_id: data ? data.questionnaire_id : '',
      code: data ? data.code : '',
      label: data ? data.label : '',
      category_id: data ? data.category_id : '',
      dependency_id: data ? data.dependency_id : 0,
      group_id: data ? data.group_id : 1,
      is_required: data ? data.is_required : false,
      max_date: data ? data.max_date : '',
      maximum: data ? data.maximum : '',
      maxlength: data ? data.maxlength : '',
      min_date: data ? data.min_date : '',
      minimum: data ? data.minimum : '',
      minlength: data ? data.minlength : '',
      options_depend_on_parent_option_value: data ? data.options_depend_on_parent_option_value : '',
      parent_question_id: data ? data.parent_question_id : 0,
      parent_value: data ? data.parent_value : '',
      question_order: data ? data.question_order : '',
      visibility_depends_on_parent_value: data ? data.visibility_depends_on_parent_value : '',
      description: data ? data.description : '',
      has_other_specify: data ? data.has_other_specify : false,
      active: data ? data.active : false,
    });
    
    const errors =
    isOpen && data && data.length > 0
    ? Array.from(
        new Set(
            data &&
            data
                .map((cons) =>
                validatorHandlers?.map((handler) => handler(cons))
                )
                .reduce((items, message) => {
                if (message) {
                    items.push(...message);
                }
                return items;
                }, [])
                .filter((error) => error)
        )
        )
    : [];

    const [errorMsg, setErrorMsg] = useState('');
    const { 
        question, 
        questionnaire_id,
        code,
        label,
        category_id,
        dependency_id,
        group_id,
        is_required,
        max_date,
        maximum,
        maxlength,
        min_date,
        minimum,
        minlength,
        options_depend_on_parent_option_value,
        parent_question_id,
        parent_value,
        question_order,
        visibility_depends_on_parent_value,
        description,
        has_other_specify,
        active 
    } = questionProp;

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     switch (name) {
    //         case 'questionnaire_id':
    //         console.log('here')
    //       if (value === '' || parseInt(value) === +value) {

    //         setQuestionGroup((prevState) => ({
    //           ...prevState,
    //           [name]: value
    //         }));
    //       }
    //       break;
    //         case 'quantity':
    //         if (value === '' || parseInt(value) === +value) {
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: value
    //             }));
    //         }
    //         break;
    //         case 'price':
    //         if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: value
    //             }));
    //         }
    //         break;
    //         case 'active':
    //         if(active){
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: false
    //           }));
    //         }else{
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: true
    //           }));
    //         }
            
    //       break;
    //         case 'repeat_questions':
    //         if(repeat_questions){
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: false
    //           }));
    //         }else{
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: true
    //           }));
    //         }
    //         default:
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: value
    //         }));
    //     }
    //     };
    const handleChange = e => {
        // console.log('here!')
        const { name, value } = e.target;
        if( name === 'is_required'){
            if(is_required){
                setQuestionProp((prevState) => ({
                  ...prevState,
                  [name]: false
                }));
              }else{
                setQuestionProp((prevState) => ({
                  ...prevState,
                  [name]: true
                }));
              }
        }else if(name === 'has_other_specify'){
            if(has_other_specify){
                setQuestionProp((prevState) => ({
                  ...prevState,
                  [name]: false
                }));
              }else{
                setQuestionProp((prevState) => ({
                  ...prevState,
                  [name]: true
                }));
              }
            }
              else if(name === 'active'){
                if(active){
                    setQuestionProp((prevState) => ({
                      ...prevState,
                      [name]: false
                    }));
                  }else{
                    setQuestionProp((prevState) => ({
                      ...prevState,
                      [name]: true
                    }));
                  }
        }else {
            setQuestionProp(questionProp => ({ ...questionProp, [name]: value }));

        }
    };
    const [select, setSelected]  = useState('');
    const [questionnaires, setOptionList] = useState([]);
    const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', currentUser.AccessToken);

    const fetchData = () => {
        fetch('http://159.223.172.47/questionnaires',{
            method: 'GET',
            headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { questionnaires } = response;
            if(response.count >= 1){
                //check the api call is success by stats code 200,201 ...etc
                setOptionList(questionnaires);
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchData();
    },[]);

    const [questionList, setquestionList] = useState([]);

    const fetchQuestionData = () => {
        fetch('http://159.223.172.47/questions',{
            method: 'GET',
            headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { questions } = response;
            if(response.count >= 1){
                //check the api call is success by stats code 200,201 ...etc
                setquestionList(questions);
                //console.log(questions)
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchQuestionData();
    },[]);

    const [selectQuesCat, setSelectQuesCat]  = useState('');
    const [questCatOptionList, setQuestCatOptionList] = useState([]);

    const fetchQuestCatData = () => {
        fetch('http://159.223.172.47/question_categories',{
            method: 'GET',
            headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { question_categories } = response;
            if(response.count >= 1){
                //check the api call is success by stats code 200,201 ...etc
                setQuestCatOptionList(question_categories);
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchQuestCatData();
    },[])

    const [selectQuesGroup, setselectQuesGroup]  = useState('');
    const [questGroupOptionList, setQuestGroupOptionList] = useState([]);

    const fetchQuestGroupData = () => {
        fetch('http://159.223.172.47/question_groups',{
            method: 'GET',
            headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { question_groups } = response;
            if(response.count >= 1){
                //check the api call is success by stats code 200,201 ...etc
                setQuestGroupOptionList(question_groups);
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchQuestGroupData();
    },[])


    //SUBMIT EDIT FORM
    const handleOnSubmitForEdit = (event) => {
    event.preventDefault();
    const values = [
        question, 
        code,
        label,
        category_id,
        questionnaire_id,
        // dependency_id,
        group_id,
        is_required,
        // max_date,
        maximum,
        maxlength,
        // min_date,
        minimum,
        minlength,
        // options_depend_on_parent_option_value,
        // parent_question_id,
        // parent_value,
        question_order,
        // visibility_depends_on_parent_value,
        // description,
        // has_other_specify,
        active
        ];            
        
        let errorMsg = '';

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '';
        });

    if (allFieldsFilled) {
        const questionProp = {
            id: data.id,
            category_id,
            code,
            dependency_id,
            description,
            group_id,
            has_other_specify,
            is_required,
            label,
            max_date,
            maximum,
            maxlength,
            min_date,
            minimum,
            minlength,
            options_depend_on_parent_option_value,
            parent_question_id,
            parent_value,
            question, 
            question_order,
            visibility_depends_on_parent_value,
            questionnaire_id,
            active 
        };
        console.log(questionProp);
        submitHandler(questionProp);
        onClose();
    } else {
        errorMsg = 'Please fill out all the fields.';
    }
    setErrorMsg(errorMsg);
    };

    return (
      <>
        <Tooltip label={tooltipLabel}>
          <IconButton
            data-testid={`${testIdPrefix}-open-modal-button`}
            size="lg"
            color="orange"
            bg="made.black"
            _hover={{ bg: "made.80" }}
            aria-label="Open modal button"
            fontSize="2xl"
            borderRadius={30}
            boxShadow="md"
            icon={<EditIcon  w={6} h={6} />}
            onClick={onOpen}
          />
          
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          {isOpen && (
            <ModalContent>
            <ModalHeader>Create Question</ModalHeader>
            <ModalCloseButton />
            <span>{errorMsg && <p className="errorMsg">{errorMsg}</p>}</span>
            <form onSubmit={handleOnSubmitForEdit}>
                <ModalBody>
                    < FormControl id="questionnaire_id" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Questionnaire
                        </FormLabel>
                        
                        <Select
                            isRequired={true}
                            placeholder='Select option'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            // type='number'
                            mb='24px'
                            fontWeight='500'
                            color="black"
                            disabled={false}
                            name='questionnaire_id'
                            value={questionProp.questionnaire_id}
                            onChange={handleChange}
                            // onChange={(e) => setSelected(e.value)}
                            >
                                {
                                questionnaires.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    < FormControl id="category_id" isRequired>
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Question Category
                            </FormLabel>
                            
                            <Select
                                isRequired={true}
                                placeholder='Select option'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                mb='24px'
                                fontWeight='500'
                                color="black"
                                disabled={false}
                                name='category_id'
                                value={questionProp.category_id}
                                onChange={handleChange}
                                // onChange={(e) => setSelected(e.value)}
                                >
                                    {
                                    questCatOptionList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                    </FormControl>

                    < FormControl id="group_id" isRequired>
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Question group
                            </FormLabel>
                            
                            <Select
                                isRequired={true}
                                placeholder='Select option'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                // type='text'
                                mb='24px'
                                fontWeight='500'
                                color="black"
                                disabled={false}
                                name='group_id'
                                value={questionProp.group_id}
                                onChange={handleChange}
                                // onChange={(e) => setSelected(e.value)}
                                >
                                    {
                                    questGroupOptionList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                    </FormControl>
                
                    <FormControl id="question" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Question
                        </FormLabel>
                        <Input
                        isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='text'
                        placeholder='Are you happy?'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="question"
                        value={questionProp.question} />
                    </FormControl>

                    <FormControl id="label" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Question Label
                        </FormLabel>
                        <Input
                        isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='text'
                        placeholder="Are you happy?"
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="label"
                        value={questionProp.label} />
                    </FormControl>

                    <FormControl id="code" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Code
                        </FormLabel>
                        <Input
                        isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='text'
                        placeholder='ML01'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        type=' text'
                        onChange={handleChange}
                        name="code" 
                        value={questionProp.code}/>
                    </FormControl>

                    <FormControl id="description" >
                        <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Description<Text color={brandStars}></Text>
                        </FormLabel>
                        <InputGroup size='md'>
                        <Input
                            fontSize='sm'
                            placeholder='how are you feeling?'
                            mb='24px'
                            size='lg'
                            type=' text'
                            name='description'
                            onChange={handleChange}
                            value={questionProp.description} />
                        </InputGroup>
                    </FormControl>

                    <FormControl id="max_date" >
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Max Date
                        </FormLabel>
                        <Input
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        placeholder='ML01'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        type='date'
                        onChange={handleChange}
                        name="max_date" 
                        value={questionProp.max_date}/>
                    </FormControl>

                    <FormControl id="maximum" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Maximum
                        </FormLabel>
                        <Input
                        // isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='0'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="maximum" 
                        value={questionProp.maximum}/>
                    </FormControl>

                    <FormControl id="maxlength" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        maximum Length
                        </FormLabel>
                        <Input
                        isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='0'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="maxlength"
                        value={questionProp.maxlength} />
                    </FormControl>

                    <FormControl id="min_date" >
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        min Date
                        </FormLabel>
                        <Input
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='date'
                        placeholder='01/01/2000'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="min_date"
                        value={questionProp.min_date} />
                    </FormControl>

                    <FormControl id="minimum" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        maximum
                        </FormLabel>
                        <Input
                        isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='2'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="minimum"
                        value={questionProp.minimum} />
                    </FormControl>
                    
                    <FormControl id="minlength" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        minimum Length
                        </FormLabel>
                        <Input
                        // isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='2'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="minlength"
                        value={questionProp.minlength} />
                    </FormControl>
                    
                    <FormControl id="options_depend_on_parent_option_value" >
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        options depend on parent option value
                        </FormLabel>
                        <Input
                        // isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='2'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="options_depend_on_parent_option_value"
                        value={questionProp.options_depend_on_parent_option_value} />
                    </FormControl>
                                    
                    < FormControl id="parent_question_id" >
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            parent question
                            </FormLabel>
                            
                            <Select
                                placeholder='Select option'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                // type='text'
                                mb='24px'
                                fontWeight='500'
                                color="black"
                                disabled={false}
                                name='parent_question_id'
                                value={questionProp.parent_question_id}
                                onChange={handleChange}
                                // onChange={(e) => setSelected(e.value)}
                                >
                                    {
                                    questionList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                    </FormControl>

                    < FormControl id="dependency_id" >
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            dependency
                            </FormLabel>
                            
                            <Select
                                placeholder='Select option'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                // type='text'
                                mb='24px'
                                fontWeight='500'
                                color="black"
                                disabled={false}
                                name='dependency_id'
                                value={questionProp.dependency_id}
                                onChange={handleChange}
                                // onChange={(e) => setSelected(e.value)}
                                >
                                    {
                                    questionList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl id="parent_value" >
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        parent value
                        </FormLabel>
                        <Input
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='text'
                        placeholder='2'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="parent_value"
                        value={questionProp.parent_value} />
                    </FormControl>

                    <FormControl id="question_order" isRequired>
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        question order
                        </FormLabel>
                        <Input
                        isRequired={true}
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='2'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="question_order"
                        value={questionProp.question_order} />
                    </FormControl>

                    <FormControl id="visibility_depends_on_parent_value" >
                        <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        visibility depends on parent value
                        </FormLabel>
                        <Input
                        // variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='2'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={handleChange}
                        name="visibility_depends_on_parent_value"
                        value={questionProp.visibility_depends_on_parent_value} />
                    </FormControl>

                    <Flex justifyContent='space-between' align='center' mb='24px'>
                        <FormControl display='flex' alignItems='center' id="has_other_specify">
                            <Checkbox  onChange={handleChange}
                            id='has_other_specify'
                            colorScheme='brandScheme'
                            me='10px'
                            name='has_other_specify'
                            value={questionProp.has_other_specify}
                            defaultChecked={questionProp.has_other_specify}
                            />
                            <FormLabel
                            htmlFor='has_other_specify'
                            mb='0'
                            fontWeight='normal'
                            color={textColor}
                            fontSize='sm'>
                            has other specify?
                            </FormLabel>
                        </FormControl>

                        <FormControl display='flex' alignItems='center' id="active">
                            <Checkbox  onChange={handleChange} 
                            id='active'
                            colorScheme='brandScheme'
                            me='10px'
                            name='active'
                            value={questionProp.active}
                            defaultChecked={questionProp.active}
                            />
                            <FormLabel
                            htmlFor='active'
                            mb='0'
                            fontWeight='normal'
                            color={textColor}
                            fontSize='sm'>
                            Active?
                            </FormLabel>
                        </FormControl>

                        <FormControl display='flex' alignItems='center' id="is_required">
                            <Checkbox  onChange={handleChange}
                            id='is_required'
                            colorScheme='brandScheme'
                            me='10px'
                            name='is_required'
                            value={questionProp.is_required}
                            defaultChecked={questionProp.is_required}
                            
                            />
                            <FormLabel
                            htmlFor='is_required'
                            mb='0'
                            fontWeight='normal'
                            color={textColor}
                            fontSize='sm'>
                            is required?
                            </FormLabel>
                        </FormControl>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                <Button
                            fontSize='sm'
                            colorScheme='green'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'
                            type="submit">
                            Submit
                        </Button>
                        <Button
                            w='20%'
                            onClick={onClose}
                            size="sm"
                            colorScheme='orange'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'>
                            Cancel
                        </Button>
                </ModalFooter>
            </form>
        </ModalContent>
          )}
        </Modal>
      </>
    );
  };
  
  export default ModalPreview;
  