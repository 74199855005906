/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState} from "react";
import { NavLink, useParams } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import DialogModal from "components/Modal/QuestionaireModal";
import QuestionTable from "views/admin/dataTables/components/QuestionaireTbl";

// Assets
// import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useSelector } from "react-redux";



function Questionaire() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [entryPage, setEntryPage] = useState(false);
  const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', currentUser.AccessToken);

  //Submit the form  -- CREATE RECORD --- 
  const handleOnSubmit = (questionCat) => {
    // console.log(questionCat);
    
    const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar',
          'x-access-token': currentUser.AccessToken
      },
      body: JSON.stringify(questionCat)
    };
    fetch('http://159.223.172.47/questionnaires', requestOptions)
        .then(response => JSON.stringify(response))
        .then(data => {
          toast({
            title: "Success!.",
            description: "Record updated successfully.",
            status: "success",
            duration: 9000,
            isClosable: true
          });
        });

        // // WORKING!!!
    // fetch('/questionnaires')
    //   .then(resp => {
    //     console.log(resp);
    //     console.log('======success=======');
    //   })
    //   .catch(err => {
    //     console.log('======failure=======');
    //     console.log(err);
    //   });
    
  };

  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Box bg="white" w="100%" p={4} color="white">

      {/* <MapChart /> */}
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "1200px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          
            <React.Fragment>
              <DialogModal handleOnSubmit={handleOnSubmit} />
            </React.Fragment>

          {/* <Flex align='center' mb='25px' w='100%'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              records
            </Text>
            <HSeparator />
          </Flex> */}
        </Flex>
      </Flex>

      <QuestionTable />
    </Box>
  );
}

export default Questionaire;
