import React, {useState} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import sha1 from 'js-sha1';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    Spinner,
    useToast
  } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { login } from "../../../actions/auth";
import Loading from "../../../components/Loading";


const LoginButton = (props) => {
  // const { loginWithRedirect } = useAuth0();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show); // password eye opener

  const [loading, setLoading] = useState(false);

  const [entryPage, setEntryPage] = useState(false);
  function doSetPage(){
    return setEntryPage(!entryPage);
  }

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const toast = useToast();

  const dispatch = useDispatch();

  let history = useHistory();

  const [signUpForm, setsignUpForm] = useState({
    email: props.email ? props.email : '',
    password: props.password ? props.password : ''

    // password: sha1('props.password') ? sha1('props.password') : ''
  });

  const [errorMsg, setErrorMsg] = useState('');
  const { email, password} = signUpForm;

 //------- SUBMIT FORM ---------------
  const handleOnSubmitSignIn = (event) => {
    event.preventDefault();
    const values = [ email, password];
    let errorMsg = '';

    setLoading(true);

    const allFieldsFilled = values.every((field) => {
      const value = `${field}`.trim();
      return value !== '' && value !== '0';
    });
    if (allFieldsFilled) {

      const signUpForm = {
        email,
        password
      };
      console.log(signUpForm);
      // props.handleOnSubmitSignIn(signUpForm);
      dispatch(login(email, password))
          .then(() => {

            history.push("/admin/default");
            // window.location.reload();
          })
          .catch(() => {
            setLoading(false);
          });
      // } else {
      //   setLoading(false);
      // }
    } else {
      errorMsg = 'Please fill out all the fields.';
    }
    setErrorMsg(errorMsg);
  };
  

  const handleChange = e => {
    const { name, value } = e.target;
    setsignUpForm(questionOption => ({ ...questionOption, [name]: value }));
    };


     const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case 'password':
          // if (value === '' || parseInt(value) === +value) {
            if (value.length > 7){

            setsignUpForm((prevState) => ({
              ...prevState,
              [name]: value.trim()
              // [name]: sha1(value)
            }));
          }
          break;
        default:
          setsignUpForm((prevState) => ({
            ...prevState,
            [name]: value
          }));
      }
    };

    if (isLoggedIn) {
      // history.push("/admin/default"); //fix this 
            // window.location.reload();
      // console.log(isLoggedIn);
    }

  return(
    // <Flex justifyContent='space-between' align='center' mb='24px'>
    //     <Button
    //         fontSize='sm'
    //         variant='brand'
    //         fontWeight='500'
    //         w='100%'
    //         h='50'
    //         mb='24px'
    //         onClick={() => loginWithRedirect()} >
    //         Log In
    //     </Button>
    // </Flex>

      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        // mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
           <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex> 
          

            {/* <Login /> */}
          <form onSubmit={handleOnSubmitSignIn}>
                        <span>{errorMsg && <p className="errorMsg">{errorMsg}</p>}</span>
            <FormControl id="signInForm" isRequired>
              <FormControl id="email" isRequired>
                <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                email
                </FormLabel>
                <InputGroup size='md'>
                <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    placeholder='mail@simmmple.com'
                    mb='24px'
                    size='lg'
                    type='text'
                    name='email'
                    onChange={handleInputChange}
                    />
                </InputGroup>
              </FormControl>
              <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Password
              </FormLabel>
              <InputGroup size='md'>
              <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  onChange={handleInputChange}
                  name='password'
              />
              
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                  />
                </InputRightElement>
                </InputGroup>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox
                    id='remember-login'
                    colorScheme='brandScheme'
                    me='10px'
                  />
                  <FormLabel
                    htmlFor='remember-login'
                    mb='0'
                    fontWeight='normal'
                    color={textColor}
                    fontSize='sm'>
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <NavLink to='/auth/forgot-password'>
                  <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                type="submit"
                disabled={loading}>
                  {loading && (
                    <Spinner 
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='lg'
                    />
                  )}
                Sign In
            </Button>
            
              {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            </FormControl>
          </form>
        </Flex>
      </Flex>

  ); 
};

export default LoginButton;