
import React, {useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Flex,
    Checkbox,
    InputGroup,
    Text,
    Select,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from "@chakra-ui/react";

import {  AddIcon, ArrowBackIcon, WarningIcon } from '@chakra-ui/icons';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const DialogModal = (props) => {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleHover = useColorModeValue(
        { bg: "gray.200" },
        { bg: "whiteAlpha.300" }
    );
    const googleActive = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const [questionOption, setquestionOption] = useState({
      question_id: props.questionId,
      description: props.questionOption ? props.questionOption.description : '',
      active: props.questionOption ? props.questionOption.active : false,
    });

   //hook to close the modal when user is done editing:
    // const [isOpen, setIsOpen] = useState(false);
  
    const [errorMsg, setErrorMsg] = useState('');
    const { question_id, description, active} = questionOption;
  
    const handleOnSubmit = (event) => {
      event.preventDefault();
      const values = [ question_id, description, active];
      let errorMsg = '';
  
      const allFieldsFilled = values.every((field) => {
        const value = `${field}`.trim();
        return value !== '' && value !== '0';
      });
      console.log(allFieldsFilled);
      if (true) {
        const questionOption = {
          question_id,
          description,
          active
        };
        // console.log(questionOption);
        props.handleOnSubmit(questionOption);
        onClose();
      } else {
        errorMsg = 'Please fill out all the fields.';
      }
      setErrorMsg(errorMsg);
    };
  
    // const handleInputChange = (event) => {
    //   const { name, value } = event.target;
    //   switch (name) {
    //     case 'questionnaire_id':
    //         console.log('here')
    //       if (value === '' || parseInt(value) === +value) {

    //         setQuestionGroup((prevState) => ({
    //           ...prevState,
    //           [name]: value
    //         }));
    //       }
    //       break;
    //       case 'active':
    //         if(active){
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: false
    //           }));
    //         }else{
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: true
    //           }));
    //         }
            
    //       break;
    //       case 'repeat_questions':
    //         if(repeat_questions){
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: false
    //           }));
    //         }else{
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: true
    //           }));
    //         }
    //       break;
    //     // case 'price':
    //     //   if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
    //     //     setQuestionGroup((prevState) => ({
    //     //       ...prevState,
    //     //       [name]: value
    //     //     }));
    //     //   }
    //     //   break;
    //     default:
    //       setQuestionGroup((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //       }));
    //   }
    // };


    const handleChange = e => {
        const { name, value } = e.target;
        setquestionOption(questionOption => ({ ...questionOption, [name]: value }));
    };

    const [select, setSelected]  = useState('');
    const [optionList, setOptionList] = useState([]);

    const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', currentUser.AccessToken);


    const fetchData = () => {
        fetch('http://159.223.172.47/questions', {
          method: 'GET',
          headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { questions } = response;
            if(response.count > 1){
                //check the api call is success by stats code 200,201 ...etc
                setOptionList(questions);
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchData();
    },[])

    
  const { isOpen, onOpen, onClose } = useDisclosure();
  let url = '/admin/questions/' + props.questionnaireId;
    return (
        <div className="main-form">
           <Flex justifyContent='space-between' color='green' align='center' mb='24px'>
                <Link
                    fontSize='sm'
                    color='black'
                    fontWeight='500'
                     to={url} >
                    <Icon as={ArrowBackIcon} w='40px' h='20px' me='10px'/> Back to questions
                </Link>

                <Button
                  fontSize='sm'
                  me='0px'
                  mb='26px'
                  py='15px'
                  h='50px'
                  borderRadius='16px'
                  bg={googleBg}
                  color='green'
                  fontWeight='500'
                  _hover={googleHover}
                  _active={googleActive}
                  _focus={googleActive}
                  onClick={onOpen} >
                  <Icon as={AddIcon} w='40px' h='20px' me='10px'/>
                  Create Question Option
              </Button>
            </Flex>
          

          <Modal isOpen={isOpen} onClose={onClose} size="4xl">
              <ModalOverlay />
              <ModalContent>
                  <ModalHeader>Create Question Option</ModalHeader>
                  <ModalCloseButton />
                  <span>{errorMsg && <p className="errorMsg">{errorMsg}</p>}</span>
                  <form onSubmit={handleOnSubmit}>
                      <ModalBody>
                        <FormControl id="description" isRequired>
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            display='flex'>
                            Description
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                isRequired={true}
                                fontSize='sm'
                                placeholder='Dscription'
                                mb='24px'
                                size='lg'
                                type=' text'
                                name='description'
                                onChange={handleChange}
                                value={description} />
                            </InputGroup>
                        </FormControl>

                        <FormControl id="question_id" isRequired>
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Question
                            </FormLabel>
                            
                          <Select
                              isRequired={true}
                              placeholder='Select option'
                              fontSize='sm'
                              ms={{ base: "0px", md: "0px" }}
                              type='text'
                              mb='24px'
                              fontWeight='500'
                              color="black"
                              disabled={false}
                              name='question_id'
                              value={props.questionId}
                              onChange={handleChange}
                              // onChange={(e) => setSelected(e.value)}
                                >
                                  {
                                  optionList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                      {item.label}
                                  </option>
                              ))}
                          </Select>
                        </FormControl>

                        <Flex justifyContent='space-between' align='center' mb='24px'>
                            <FormControl display='flex' alignItems='center' id="active">
                                <Checkbox  onChange={handleChange} 
                                  id='active'
                                  colorScheme='brandScheme'
                                  me='10px'
                                  name='active'
                                  value={questionOption.active}
                                />
                                <FormLabel
                                  htmlFor='active'
                                  mb='0'
                                  fontWeight='normal'
                                  color={textColor}
                                  fontSize='sm'>
                                  Active?
                                </FormLabel>
                            </FormControl>
                        </Flex>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          fontSize='sm'
                          colorScheme='green'
                          fontWeight='500'
                          variant='ghost'
                          w='100%'
                          h='50'
                          mb='24px'
                          type="submit">
                          Submit
                        </Button>
                      </ModalFooter>
                  </form>
              </ModalContent>
          </Modal>
        </div>
    )
}

export default DialogModal;

