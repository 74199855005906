import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
    // console.log(user);
  if (isLoading) {
    return "Loading ...";
  }

  return (
    isAuthenticated && (
    //   <div>
    //     <img src={user.picture} alt={user.name} />
            user.name
    //     <p>{user.email}</p>
    //   </div>
    )
  );
};

export default Profile;