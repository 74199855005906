import React from 'react'

import Testimonials from '../misc/Testimonials'
import Pricing from '../misc/Pricing'
import Header from '../misc/Header'
import Layout from '../../layouts/Layout'


const Home = () => {
    return (
        <React.Fragment>
            {/* <Layout> */}
                <Header />
                {/* <Pricing /> */}
                <Testimonials />
            {/* </Layout> */}
            
        </React.Fragment>
    )
}

export default Home;

/*
            <Pricing />
            <Testimonials />

*/