import React, {useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Th,
    IconButton,
    Tooltip,
    Alert,
    AlertIcon,
  } from "@chakra-ui/react";
import { EditIcon } from '@chakra-ui/icons';

  
  type ModalPreviewProps = {
    validatorHandlers?: { (data: any): string | null }[];
    //submitHandler?: (data: any[]) => void;
    invalidConsignmentMsg?: string;
    data?: any[];
    testIdPrefix?: string;
    modalHeader: string;
    buttonIcon: EditIcon;
    tooltipLabel: string;
  };
  
  const ModalPreview = ({
    validatorHandlers,
    data,
    testIdPrefix,
    modalHeader,
    buttonIcon,
    tooltipLabel,
    submitHandler,
  }: ModalPreviewProps): JSX.Element => {
    // console.log(submitHandler);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [questionCat, setquestionCat] = useState({
        name: data ? data.name : '',
        description: data ? data.description : '',
      });
    
    const errors =
      isOpen && data && data.length > 0
        ? Array.from(
            new Set(
              data &&
                data
                  .map((cons) =>
                    validatorHandlers?.map((handler) => handler(cons))
                  )
                  .reduce((items, message) => {
                    if (message) {
                      items.push(...message);
                    }
                    return items;
                  }, [])
                  .filter((error) => error)
            )
          )
        : [];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'quantity':
            if (value === '' || parseInt(value) === +value) {
                setquestionCat((prevState) => ({
                ...prevState,
                [name]: value
                }));
            }
            break;
            case 'price':
            if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
                setquestionCat((prevState) => ({
                ...prevState,
                [name]: value
                }));
            }
            break;
            default:
            setquestionCat((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
        };

        const [errorMsg, setErrorMsg] = useState('');
         const { name, description } = questionCat;
         //==========SUBMIT FORM============
        const handleOnSubmitForEdit = (event) => {
            event.preventDefault();
            const values = [name, description];
            let errorMsg = '';
        
            const allFieldsFilled = values.every((field) => {
              const value = `${field}`.trim();
              return value !== '' && value !== '0';
            });
        
            if (allFieldsFilled) {
              const questionCat = {
                id: data.id,
                name,
                description,
              //date: new Date()
              };
              submitHandler(questionCat); 
              onClose(); // closes modal
            } else {
              errorMsg = 'Please fill out all the fields.';
            }
            setErrorMsg(errorMsg);
          };

    return (
      <>
        <Tooltip label={tooltipLabel}>
          <IconButton
            data-testid={`${testIdPrefix}-open-modal-button`}
            size="lg"
            color="orange"
            bg="made.black"
            _hover={{ bg: "made.80" }}
            aria-label="Open modal button"
            fontSize="2xl"
            borderRadius={30}
            boxShadow="md"
            icon={<EditIcon  w={6} h={6} />}
            onClick={onOpen}
          />
          
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          {isOpen && (
            <ModalContent>
                <form onSubmit={handleOnSubmitForEdit}>

                    <ModalHeader>{modalHeader}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {errors.map((error, index) => (
                        <Alert
                            key={index}
                            type={"error"}
                            boxShadow="base"
                            borderRadius={5} >
                            <AlertIcon />
                            {error}
                        </Alert>
                        ))}
                        <FormControl id="name" isRequired>
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            // color={textColor}
                            mb='8px'>
                            Name
                            </FormLabel>
                            <Input
                            isRequired={true}
                            // variant='auth'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            type='text'
                            placeholder='General Data Collection'
                            mb='24px'
                            fontWeight='500'
                            size='lg'
                            value={questionCat.name}
                            onChange={handleInputChange}
                            name="name" />
                        </FormControl>
                        <FormControl id="description">
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            // color={textColor}
                            display='flex'>
                            Description
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                fontSize='sm'
                                placeholder='Optional Diescription'
                                mb='24px'
                                size='lg'
                                name='description'
                                onChange={handleInputChange}
                                value={questionCat.description}
                                />

                            </InputGroup>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            fontSize='sm'
                            variant='ghost'
                            colorScheme='green'
                            fontWeight='500'
                            w='20%'
                            h='50'
                            mb='24px'
                            type="submit">
                            Submit
                        </Button>
                        <Button
                            w='20%'
                            onClick={onClose}
                            size="sm"
                            colorScheme='orange'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'>
                            Cancel
                        </Button>
                </ModalFooter>
            </form>

            </ModalContent>
          )}
        </Modal>
      </>
    );
  };
  
  export default ModalPreview;
  