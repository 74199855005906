import React, {useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Flex,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    IconButton,
    Tooltip,
    Alert,
    useColorModeValue,
    AlertIcon,
  } from "@chakra-ui/react";
import { EditIcon } from '@chakra-ui/icons';

  
  type ModalPreviewProps = {
    validatorHandlers?: { (data: any): string | null }[];
    //submitHandler?: (data: any[]) => void;
    invalidConsignmentMsg?: string;
    data?: any[];
    testIdPrefix?: string;
    modalHeader: string;
    buttonIcon: EditIcon;
    tooltipLabel: string;
  };
  
  const ModalPreview = ({
    validatorHandlers,
    data,
    testIdPrefix,
    modalHeader,
    buttonIcon,
    tooltipLabel,
    submitHandler,
  }: ModalPreviewProps): JSX.Element => {
    // console.log(submitHandler);
    const textColor = useColorModeValue("navy.700", "white");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [questionCat, setquestionCat] = useState({
        name: data ? data.name : '',
        description: data ? data.description : '',
        code: data ? data.code : '',
        version: data ? data.version : '',
        capture_geolocation: data ? data.capture_geolocation : false,
        active: data ? data.active : false,
      });
    
    const errors =
      isOpen && data && data.length > 0
        ? Array.from(
            new Set(
              data &&
                data
                  .map((cons) =>
                    validatorHandlers?.map((handler) => handler(cons))
                  )
                  .reduce((items, message) => {
                    if (message) {
                      items.push(...message);
                    }
                    return items;
                  }, [])
                  .filter((error) => error)
            )
          )
        : [];

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      if( name === 'capture_geolocation'){
        if(capture_geolocation){
            setquestionCat((prevState) => ({
              ...prevState,
              [name]: false
            }));
          }else{
            setquestionCat((prevState) => ({
              ...prevState,
              [name]: true
            }));
          }
      }else if(name === 'active'){
          if(active){
            setquestionCat((prevState) => ({
                  ...prevState,
                  [name]: false
              }));
              }else{
                setquestionCat((prevState) => ({
                  ...prevState,
                  [name]: true
              }));
              }
      }else {
        setquestionCat(questionProp => ({ ...questionProp, [name]: value }));
      }
        };

        const [errorMsg, setErrorMsg] = useState('');
        const { name, description, code, version, capture_geolocation, active} = questionCat;

         //SUBMIT EDIT FORM
        const handleOnSubmitForEdit = (event) => {
            event.preventDefault();
            const values = [name, version, code, description, capture_geolocation, active];
            let errorMsg = '';
        
            const allFieldsFilled = values.every((field) => {
              const value = `${field}`.trim();
              return value !== '' && value !== '0';
            });
        
            if (allFieldsFilled) {
              const questionCat = {
                id: data.id,
                code,
                name,
                description,
                version,
                capture_geolocation,
                active
              };
              submitHandler(questionCat);
              onClose();
            } else {
              errorMsg = 'Please fill out all the fields.';
            }
            setErrorMsg(errorMsg);
          };

    return (
      <>
        <Tooltip label={tooltipLabel}>
          <IconButton
            data-testid={`${testIdPrefix}-open-modal-button`}
            size="sm"
            color="orange"
            bg="made.black"
            _hover={{ bg: "made.80" }}
            aria-label="Open modal button"
            fontSize="2xl"
            borderRadius={30}
            boxShadow="md"
            icon={<EditIcon  w={5} h={5} />}
            onClick={onOpen}
          />
          
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          {isOpen && (
            <ModalContent>
                <form onSubmit={handleOnSubmitForEdit}>

                    <ModalHeader>{modalHeader}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {errors.map((error, index) => (
                        <Alert
                            key={index}
                            type={"error"}
                            boxShadow="base"
                            borderRadius={5} >
                            <AlertIcon />
                            {error}
                        </Alert>
                        ))}
                        <FormControl id="name" isRequired>
                            <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Name
                            </FormLabel>
                            <Input
                            isRequired={true}
                            // variant='auth'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            type='text'
                            placeholder='General Data Collection'
                            mb='24px'
                            fontWeight='500'
                            size='lg'
                            value={questionCat.name}
                            onChange={handleInputChange}
                            name="name" />
                        </FormControl>

                        <FormControl id="code" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Code
                                  </FormLabel>
                                  <Input
                                  isRequired={true}
                                  // variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  type='text'
                                  placeholder='ML01'
                                  mb='24px'
                                  fontWeight='500'
                                  size='lg'
                                  type=' text'
                                  onChange={handleInputChange}
                                  name="code" 
                                  value={questionCat.code}
                                  />
                        </FormControl>

                        <FormControl id="version" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Version
                                  </FormLabel>
                                  <Input
                                  isRequired={true}
                                  // variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  type=' text'
                                  placeholder='2'
                                  mb='24px'
                                  fontWeight='500'
                                  size='lg'
                                  onChange={handleInputChange}
                                  name="version"
                                  value={questionCat.version}
                                   />
                        </FormControl>

                        <FormControl id="description">
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            // color={textColor}
                            display='flex'>
                            Description
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                fontSize='sm'
                                placeholder='Optional Diescription'
                                mb='24px'
                                size='lg'
                                name='description'
                                onChange={handleInputChange}
                                value={questionCat.description}
                                />

                            </InputGroup>
                        </FormControl>

                        <Flex justifyContent='space-between' align='center' mb='24px'>
                            <FormControl display='flex' alignItems='center' id="capture_geolocation">
                                <Checkbox  onChange={handleInputChange}
                                id='capture_geolocation'
                                colorScheme='brandScheme'
                                me='10px'
                                name='capture_geolocation'
                                value={questionCat.capture_geolocation}
                                defaultChecked={questionCat.capture_geolocation}
                                />
                                <FormLabel
                                htmlFor='capture_geolocation'
                                mb='0'
                                fontWeight='normal'
                                color={textColor}
                                fontSize='sm'>
                                capture geolocation?
                                </FormLabel>
                            </FormControl>

                            <FormControl display='flex' alignItems='center' id="active">
                                <Checkbox  onChange={handleInputChange} 
                                id='active'
                                colorScheme='brandScheme'
                                me='10px'
                                name='active'
                                value={questionCat.active}
                                defaultChecked={questionCat.active}
                                />
                                <FormLabel
                                htmlFor='active'
                                mb='0'
                                fontWeight='normal'
                                color={textColor}
                                fontSize='sm'>
                                Active?
                                </FormLabel>
                            </FormControl>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            fontSize='sm'
                            colorScheme='green'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'
                            type="submit">
                            Submit
                        </Button>
                        <Button
                            w='20%'
                            onClick={onClose}
                            size="sm"
                            colorScheme='orange'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'>
                            Cancel
                        </Button>
                </ModalFooter>
            </form>

            </ModalContent>
          )}
        </Modal>
      </>
    );
  };
  
  export default ModalPreview;
  