import React, {useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Text} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../../actions/auth";
import { clearMessage } from "../../../actions/message";

const LogoutButton = () => {
  // const { logout } = useAuth0(); 
  const { user: currentUser } = useSelector((state) => state.auth);
  
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    // alert('here');
    dispatch(logout);
  }, [dispatch]);

  return (
    // <Text onClick={() => logout({ returnTo: "https://127.0.0.1:3000/horizon-ui-chakra#/auth/sign-in/" })}>
    //   Log Out
    // </Text>
    <a href="/#/auth/sign-in/" className="nav-link" onClick={logOut}>
      Log Out
    </a>
  );
};

export default LogoutButton;