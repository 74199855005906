
import React, {useState} from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import './css/index.css';
import { Icon } from "leaflet";
import * as parkData from "./data/skateboard-parks.json";
import useSwr from "swr";
import { useSelector } from "react-redux";



function MapComponent(props) {

    const fetcher = (...args) => fetch(...args).then(response => response.json());
    const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

    const config = React.useMemo(
      () => ({
        headers: {
          'x-access-token': currentUser.AccessToken,
          'Content-Type': 'application/json',
        },
      }),
      [currentUser.AccessToken]
    );

  const { data, error } = useSwr([props.url, config],  fetcher );
  console.log(data);
  const locations = data ? data.slice(0, 100) : [];

    const skater = new Icon({
        iconUrl: "/img/marker-icon-red.png",
        iconSize: [25, 25]
      });

      let sn = 1;


  return (
    <>

        <MapContainer center={[9.8965, 8.8583]} zoom={12    }scrollWheelZoom={false}> {/* 9.8965, 8.8583 - JOS CORDINATES*/}
            <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {locations && locations.map(location  => (
                    <Marker
                        key={sn++}
                        position={[location.Latitude, location.Longitude]}>

                        <Popup>
                            {location.Community && location.Community}
                        </Popup>
                    </Marker>
                ))}
        </MapContainer>
    </>
  );
}

export default MapComponent;