import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdQuestionAnswer,
  MdQuiz,
  MdAlbum,
  MdMapsUgc,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import QuestionCategory from "views/admin/questionaire/questionaire_category";
import Questionaire from "views/admin/questionaire/questionaire";
import QuestionGroup from "views/admin/questionaire/question_group";
import Question from "views/admin/questionaire/question";
import QuestionOption from "views/admin/questionaire/questionOption";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import MapComponent from "views/admin/questionaire/map";
import { SiGooglemaps } from "react-icons/si";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  // {
  //   name: "Map",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Map",
  //   layout: "/admin",
  //   path: "/map",
  //   icon: (
  //     <Icon
  //       as={SiGooglemaps}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: MapComponent,
  //   secondary: true,
  // },
  {
    name: "Question Category",
    layout: "/admin",
    path: "/que-category",
    icon: (
      <Icon
        as={MdAlbum}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: QuestionCategory,
    secondary: true,
  },
  {
    name: "Question Groups",
    layout: "/admin",
    path: "/question-groups",
    icon: (
      <Icon
        as={MdQuestionAnswer}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: QuestionGroup,
    secondary: true,
  },
  {
    name: "Questionnaire",
    layout: "/admin",
    path: "/questionnaire",
    icon: (
      <Icon
        as={MdQuiz}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Questionaire,
    secondary: true,
  },
  // {
  //   name: "Questions",
  //   layout: "/admin",
  //   path: "/questions",
  //   icon: (
  //     <Icon
  //       as={MdQuestionAnswer}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: Question,
  //   secondary: true,
  // },
  // {
  //   name: "Question's Options",
  //   layout: "/admin",
  //   path: "/question-option",
  //   icon: (
  //     <Icon
  //       as={MdQuestionAnswer}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: QuestionOption,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
