
import React, {useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Flex,
    Checkbox,
    InputGroup,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from "@chakra-ui/react";

import {  AddIcon, WarningIcon } from '@chakra-ui/icons';



const DialogModal = (props) => {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleHover = useColorModeValue(
        { bg: "gray.200" },
        { bg: "whiteAlpha.300" }
    );
    const googleActive = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const [questionaire, setBook] = useState({
      name: props.questionaire ? props.questionaire.name : '',
      code: props.questionaire ? props.questionaire.code : '',
      version: props.questionaire ? props.questionaire.version : '',
      description: props.questionaire ? props.questionaire.description : '',
      capture_geolocation: props.questionaire ? props.questionaire.capture_geolocation : false,
      active: props.questionaire ? props.questionaire.active : false,
    });

   //hook to close the modal when user is done editing:
    // const [isOpen, setIsOpen] = useState(false);
  
    const [errorMsg, setErrorMsg] = useState('');
    const { name, code, version, description, capture_geolocation, active} = questionaire;
  
    const handleOnSubmit = (event) => {
      event.preventDefault();
      const values = [name, code, version, description, capture_geolocation, active];
      let errorMsg = '';
  
      const allFieldsFilled = values.every((field) => {
        const value = `${field}`.trim();
        return value !== '' && value !== '0';
      });
  
      if (allFieldsFilled) {
        const questionaire = {
          // id: uuidv4(),
          name,
          code,
          version,
          description,
          capture_geolocation,
          active
        //date: new Date()
        };
        props.handleOnSubmit(questionaire);
        onClose();
      } else {
        errorMsg = 'Please fill out all the fields.';
      }
      setErrorMsg(errorMsg);
    };
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case 'quantity':
          if (value === '' || parseInt(value) === +value) {
            setBook((prevState) => ({
              ...prevState,
              [name]: value
            }));
          }
          break;
          case 'active':
            if(active){
              setBook((prevState) => ({
                ...prevState,
                [name]: false
              }));
            }else{
              setBook((prevState) => ({
                ...prevState,
                [name]: true
              }));
            }
            
          break;
          case 'capture_geolocation':
            if(capture_geolocation){
              setBook((prevState) => ({
                ...prevState,
                [name]: false
              }));
            }else{
              setBook((prevState) => ({
                ...prevState,
                [name]: true
              }));
            }
          break;
        case 'price':
          if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
            setBook((prevState) => ({
              ...prevState,
              [name]: value
            }));
          }
          break;
        default:
          console.log(value);
          setBook((prevState) => ({
            ...prevState,
            [name]: value
          }));
      }
    };

    
  const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <div className="main-form">
          <Button
              fontSize='sm'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg={googleBg}
              color='green'
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
              onClick={onOpen} >
              <Icon as={AddIcon} w='40px' h='20px' me='10px'/>
              Create Questionaire
          </Button>

          <Modal isOpen={isOpen} onClose={onClose} size="4xl">
              <ModalOverlay />
              <ModalContent>
                  <ModalHeader>Create Questionaire</ModalHeader>
                  <ModalCloseButton />
                  <span>{errorMsg && <p className="errorMsg">{errorMsg}</p>}</span>
                  <form onSubmit={handleOnSubmit}>
                      <ModalBody>
                          
                              <FormControl id="name" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Name
                                  </FormLabel>
                                  <Input
                                  isRequired={true}
                                  // variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  type='text'
                                  placeholder='General Data Collection'
                                  mb='24px'
                                  fontWeight='500'
                                  size='lg'
                                  onChange={handleInputChange}
                                  name="name"
                                  value={name} />
                              </FormControl>

                              <FormControl id="code" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Code
                                  </FormLabel>
                                  <Input
                                  isRequired={true}
                                  // variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  type='text'
                                  placeholder='ML01'
                                  mb='24px'
                                  fontWeight='500'
                                  size='lg'
                                  type=' text'
                                  onChange={handleInputChange}
                                  name="code" 
                                  value={code}/>
                              </FormControl>

                              <FormControl id="version" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Version
                                  </FormLabel>
                                  <Input
                                  isRequired={true}
                                  // variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  type=' text'
                                  placeholder='2'
                                  mb='24px'
                                  fontWeight='500'
                                  size='lg'
                                  onChange={handleInputChange}
                                  name="version"
                                  value={version} />
                              </FormControl>

                              <FormControl id="description" isRequired>
                                  <FormLabel
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  display='flex'>
                                  Description<Text color={brandStars}></Text>
                                  </FormLabel>
                                  <InputGroup size='md'>
                                  <Input
                                      isRequired={true}
                                      fontSize='sm'
                                      placeholder='Dscription'
                                      mb='24px'
                                      size='lg'
                                      type=' text'
                                      name='description'
                                      onChange={handleInputChange}
                                      value={description} />
                                  </InputGroup>
                              </FormControl>

                              <Flex justifyContent='space-between' align='center' mb='24px'>
                                  <FormControl display='flex' alignItems='center' id="capture_geolocation">
                                      <Checkbox  onChange={handleInputChange}
                                      id='capture_geolocation'
                                      colorScheme='brandScheme'
                                      me='10px'
                                      name='capture_geolocation'
                                      />
                                      <FormLabel
                                      htmlFor='capture_geolocation'
                                      mb='0'
                                      fontWeight='normal'
                                      color={textColor}
                                      fontSize='sm'>
                                      capture geolocation?
                                      </FormLabel>
                                  </FormControl>

                                  <FormControl display='flex' alignItems='center' id="active">
                                      <Checkbox  onChange={handleInputChange} 
                                      id='active'
                                      colorScheme='brandScheme'
                                      me='10px'
                                      name='active'
                                      />
                                      <FormLabel
                                      htmlFor='active'
                                      mb='0'
                                      fontWeight='normal'
                                      color={textColor}
                                      fontSize='sm'>
                                      Active?
                                      </FormLabel>
                                  </FormControl>
                              </Flex>
                      </ModalBody>

                      <ModalFooter>
                          <Button
                              fontSize='sm'
                              variant='ghost'
                              colorScheme='green'
                              fontWeight='500'
                              w='100%'
                              h='50'
                              mb='24px'
                              type="submit">
                              Submit
                          </Button>
                      </ModalFooter>
                  </form>
              </ModalContent>
          </Modal>
        </div>
    )
}

export default DialogModal;

