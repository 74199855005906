
import React, {useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Flex,
    Checkbox,
    InputGroup,
    Text,
    Select,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from "@chakra-ui/react";

import {  AddIcon, WarningIcon } from '@chakra-ui/icons';
import { useSelector } from "react-redux";


const DialogModal = (props) => {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleHover = useColorModeValue(
        { bg: "gray.200" },
        { bg: "whiteAlpha.300" }
    );
    const googleActive = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const [questionGroup, setQuestionGroup] = useState({
      name: props.questionGroup ? props.questionGroup.name : '',
      questionnaire_id: props.questionGroup ? props.questionGroup.questionnaire_id : '',
      description: props.questionGroup ? props.questionGroup.description : '',
      repeat_questions: props.questionGroup ? props.questionGroup.repeat_questions : false,
      active: props.questionGroup ? props.questionGroup.active : false,
    });

   //hook to close the modal when user is done editing:
    // const [isOpen, setIsOpen] = useState(false);
  
    const [errorMsg, setErrorMsg] = useState('');
    const { name, questionnaire_id, description, repeat_questions, active} = questionGroup;
  
    const handleOnSubmit = (event) => {
      event.preventDefault();
      const values = [name, questionnaire_id, description, repeat_questions, active];
      let errorMsg = '';
  
      const allFieldsFilled = values.every((field) => {
        const value = `${field}`.trim();
        return value !== '' && value !== '0';
      });
      console.log(allFieldsFilled);
      if (true) {
        const questionGroup = {
          // id: uuidv4(),
          name,
          questionnaire_id,
          description,
          repeat_questions,
          active
        //date: new Date()
        };
        // console.log(questionGroup);
        props.handleOnSubmit(questionGroup);
        onClose();
      } else {
        errorMsg = 'Please fill out all the fields.';
      }
      setErrorMsg(errorMsg);
    };
  
    // const handleInputChange = (event) => {
    //   const { name, value } = event.target;
    //   switch (name) {
    //     case 'questionnaire_id':
    //         console.log('here')
    //       if (value === '' || parseInt(value) === +value) {

    //         setQuestionGroup((prevState) => ({
    //           ...prevState,
    //           [name]: value
    //         }));
    //       }
    //       break;
    //       case 'active':
    //         if(active){
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: false
    //           }));
    //         }else{
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: true
    //           }));
    //         }
            
    //       break;
    //       case 'repeat_questions':
    //         if(repeat_questions){
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: false
    //           }));
    //         }else{
    //             setQuestionGroup((prevState) => ({
    //             ...prevState,
    //             [name]: true
    //           }));
    //         }
    //       break;
    //     // case 'price':
    //     //   if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
    //     //     setQuestionGroup((prevState) => ({
    //     //       ...prevState,
    //     //       [name]: value
    //     //     }));
    //     //   }
    //     //   break;
    //     default:
    //       setQuestionGroup((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //       }));
    //   }
    // };


    const handleChange = e => {
        // console.log('here!')
        const { name, value } = e.target;
        if( name === 'repeat_questions'){
            if(repeat_questions){
              setQuestionGroup((prevState) => ({
                  ...prevState,
                  [name]: false
                }));
              }else{
                setQuestionGroup((prevState) => ({
                  ...prevState,
                  [name]: true
                }));
              }
        }
        // else if(name === 'has_other_specify'){
        //     if(is_required){
        //         setQuestionProp((prevState) => ({
        //           ...prevState,
        //           [name]: false
        //         }));
        //       }else{
        //         setQuestionProp((prevState) => ({
        //           ...prevState,
        //           [name]: true
        //         }));
        //       }
        //     }
              else if(name === 'active'){
                if(active){
                  setQuestionGroup((prevState) => ({
                      ...prevState,
                      [name]: false
                    }));
                  }else{
                    setQuestionGroup((prevState) => ({
                      ...prevState,
                      [name]: true
                    }));
                  }
        }else {
          setQuestionGroup(questionProp => ({ ...questionProp, [name]: value }));

        }
    };

    const [select, setSelected]  = useState('');
    const [optionList, setOptionList] = useState([]);
    const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', currentUser.AccessToken);

    const fetchData = () => {
        fetch('http://159.223.172.47/questionnaires', {
          method: 'GET',
          headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { questionnaires } = response;
            // console.log(questionnaires);
            if(response.count >= 1){
                //check the api call is success by stats code 200,201 ...etc
                setOptionList(questionnaires);
                // console.log(optionList);
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchData();
    },[])

    
  const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <div className="main-form">
          <Button
              fontSize='sm'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg={googleBg}
              color='green'
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
              onClick={onOpen} >
              <Icon as={AddIcon} w='40px' h='20px' me='10px'/>
              Create Question Group
          </Button>

          <Modal isOpen={isOpen} onClose={onClose} size="4xl">
              <ModalOverlay />
              <ModalContent>
                  <ModalHeader>Create Question Group</ModalHeader>
                  <ModalCloseButton />
                  <span>{errorMsg && <p className="errorMsg">{errorMsg}</p>}</span>
                  <form onSubmit={handleOnSubmit}>
                      <ModalBody>
                          
                              <FormControl id="name" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Name
                                  </FormLabel>
                                  <Input
                                  isRequired={true}
                                  // variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  type='text'
                                  placeholder='General Data Collection'
                                  mb='24px'
                                  fontWeight='500'
                                  size='lg'
                                  onChange={handleChange}
                                  name="name"
                                  value={name} />
                              </FormControl>

                              <FormControl id="description" isRequired>
                                  <FormLabel
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  display='flex'>
                                  Description<Text color={brandStars}></Text>
                                  </FormLabel>
                                  <InputGroup size='md'>
                                  <Input
                                      isRequired={true}
                                      fontSize='sm'
                                      placeholder='Dscription'
                                      mb='24px'
                                      size='lg'
                                      type=' text'
                                      name='description'
                                      onChange={handleChange}
                                      value={description} />
                                  </InputGroup>
                              </FormControl>

                              <FormControl id="questionnaire_id" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Questionnaire
                                  </FormLabel>
                                  
                                <Select
                                    isRequired={true}
                                    placeholder='Select option'
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    mb='24px'
                                    fontWeight='500'
                                    color="black"
                                    disabled={false}
                                    name='questionnaire_id'
                                    value={questionGroup.questionnaire_id}
                                    onChange={handleChange}
                                    // onChange={(e) => setSelected(e.value)}
                                     >
                                        {
                                        optionList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                              </FormControl>

                              <Flex justifyContent='space-between' align='center' mb='24px'>
                                  <FormControl display='flex' alignItems='center' id="repeat_questions">
                                      <Checkbox  onChange={handleChange}
                                      id='repeat_questions'
                                      colorScheme='brandScheme'
                                      me='10px'
                                      name='repeat_questions'
                                    value={questionGroup.repeat_questions}
                                    />
                                      <FormLabel
                                      htmlFor='repeat_questions'
                                      mb='0'
                                      fontWeight='normal'
                                      color={textColor}
                                      fontSize='sm'>
                                      repeat questions?
                                      </FormLabel>
                                  </FormControl>

                                  <FormControl display='flex' alignItems='center' id="active">
                                      <Checkbox  onChange={handleChange} 
                                        id='active'
                                        colorScheme='brandScheme'
                                        me='10px'
                                        name='active'
                                        value={questionGroup.active}
                                      />
                                      <FormLabel
                                        htmlFor='active'
                                        mb='0'
                                        fontWeight='normal'
                                        color={textColor}
                                        fontSize='sm'>
                                        Active?
                                      </FormLabel>
                                  </FormControl>
                              </Flex>
                      </ModalBody>

                      <ModalFooter>
                          <Button
                              fontSize='sm'
                              colorScheme='green'
                              fontWeight='500'
                              variant='ghost'
                              w='100%'
                              h='50'
                              mb='24px'
                              type="submit">
                              Submit
                          </Button>
                      </ModalFooter>
                  </form>
              </ModalContent>
          </Modal>
        </div>
    )
}

export default DialogModal;

