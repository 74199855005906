import React from "react";


const IFrame = (props) => {
    return (

            <div>          
                <iframe src={props.src} height={props.height} width={props.width}/>         
            </div>

        );
    };


export default IFrame;