import React, {useState, useEffect} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Flex,
    Select,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    IconButton,
    Tooltip,
    Alert,
    useColorModeValue,
    AlertIcon,
  } from "@chakra-ui/react";
import { EditIcon } from '@chakra-ui/icons';
import { useSelector } from "react-redux";
  
  type ModalPreviewProps = {
    validatorHandlers?: { (data: any): string | null }[];
    //submitHandler?: (data: any[]) => void;
    invalidConsignmentMsg?: string;
    data?: any[];
    testIdPrefix?: string;
    modalHeader: string;
    buttonIcon: EditIcon;
    tooltipLabel: string;
  };
  
  const ModalPreview = ({
    validatorHandlers,
    data,
    testIdPrefix,
    modalHeader,
    buttonIcon,
    tooltipLabel,
    submitHandler,
  }: ModalPreviewProps): JSX.Element => {
    // console.log(submitHandler);
    const textColor = useColorModeValue("navy.700", "white");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [questionOption, setQuestionOption] = useState({
        description: data ? data.description : '',
        question_id: data ? data.question_id : '',
        active: data ? data.active : false,
      });
    
    const errors =
      isOpen && data && data.length > 0
        ? Array.from(
            new Set(
              data &&
                data
                  .map((cons) =>
                    validatorHandlers?.map((handler) => handler(cons))
                  )
                  .reduce((items, message) => {
                    if (message) {
                      items.push(...message);
                    }
                    return items;
                  }, [])
                  .filter((error) => error)
            )
          )
        : [];

    const handleChange = e => {
        const { name, value } = e.target;
         if(name === 'active'){
            if(active){
                setQuestionOption((prevState) => ({
                    ...prevState,
                    [name]: false
                }));
                }else{
                    setQuestionOption((prevState) => ({
                    ...prevState,
                    [name]: true
                }));
                }
        }else {
            setQuestionOption(questionProp => ({ ...questionProp, [name]: value }));

        }
    };
    const [select, setSelected]  = useState('');
    const [optionList, setOptionList] = useState([]);
    const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', currentUser.AccessToken);

    const fetchData = () => {
        fetch('http://159.223.172.47/questions',{
          method: 'GET',
          headers: myHeaders,
        }).then(response => response.json())
          .then((response) => {
            const { questions } = response;
            if(response.count > 1){
                //check the api call is success by stats code 200,201 ...etc
                setOptionList(questions);
            }else{
                //error handle section 
                console.log('error!');
            }
          })
          .catch((error) => console.log(error));
      };

      useEffect(()=>{
        fetchData();
    },[])

        const [errorMsg, setErrorMsg] = useState('');
        const { description, active, question_id } = questionOption;

         //SUBMIT EDIT FORM
        const handleOnSubmitForEdit = (event) => {
            event.preventDefault();
            const values = [ description, active, question_id, ];
            let errorMsg = '';
        
            const allFieldsFilled = values.every((field) => {
              const value = `${field}`.trim();
              return value !== '' && value !== '0';
            });
        
            if (allFieldsFilled) {
              const questionOption = {
                id: data.id,
                description,
                active,
                question_id,
              };
              console.log(questionOption);
              submitHandler(questionOption);
              onClose();
            } else {
              errorMsg = 'Please fill out all the fields.';
            }
            setErrorMsg(errorMsg);
          };

    return (
      <>
        <Tooltip label={tooltipLabel}>
          <IconButton
            data-testid={`${testIdPrefix}-open-modal-button`}
            size="lg"
            color="orange"
            bg="made.black"
            _hover={{ bg: "made.80" }}
            aria-label="Open modal button"
            fontSize="2xl"
            borderRadius={30}
            boxShadow="md"
            icon={<EditIcon  w={6} h={6} />}
            onClick={onOpen}
          />
          
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          {isOpen && (
            <ModalContent>
                <form onSubmit={handleOnSubmitForEdit}>

                    <ModalHeader>{modalHeader}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {errors.map((error, index) => (
                        <Alert
                            key={index}
                            type={"error"}
                            boxShadow="base"
                            borderRadius={5} >
                            <AlertIcon />
                            {error}
                        </Alert>
                        ))}

                        <FormControl id="description">
                            <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            // color={textColor}
                            display='flex'>
                            Description
                            </FormLabel>
                            <InputGroup size='md'>
                            <Input
                                fontSize='sm'
                                placeholder='Optional Diescription'
                                mb='24px'
                                size='lg'
                                name='description'
                                onChange={handleChange}
                                value={questionOption.description}
                                />

                            </InputGroup>
                        </FormControl>

                        <FormControl id="question_id" isRequired>
                                  <FormLabel
                                  display='flex'
                                  ms='4px'
                                  fontSize='sm'
                                  fontWeight='500'
                                  color={textColor}
                                  mb='8px'>
                                  Question
                                  </FormLabel>
                                  
                                <Select
                                    isRequired={true}
                                    placeholder='Select option'
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    mb='24px'
                                    fontWeight='500'
                                    color="black"
                                    disabled={false}
                                    name='question_id'
                                    defaultValue={questionOption.question_id}
                                    onChange={handleChange}
                                    // onChange={e => handleInputChange(e)}
                                     >
                                        {
                                        optionList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                              </FormControl>

                        <Flex justifyContent='space-between' align='center' mb='24px'>

                            <FormControl display='flex' alignItems='center' id="active">
                                <Checkbox  onChange={handleChange} 
                                id='active'
                                colorScheme='brandScheme'
                                me='10px'
                                name='active'
                                // value={questionOption.active}
                                defaultChecked={questionOption.active}

                                />
                                <FormLabel
                                htmlFor='active'
                                mb='0'
                                fontWeight='normal'
                                color={textColor}
                                fontSize='sm'>
                                Active?
                                </FormLabel>
                            </FormControl>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            fontSize='sm'
                            colorScheme='green'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'
                            type="submit">
                            Submit
                        </Button>
                        <Button
                            w='20%'
                            onClick={onClose}
                            size="sm"
                            colorScheme='orange'
                            fontWeight='500'
                            variant='ghost'
                            w='20%'
                            h='50'
                            mb='24px'>
                            Cancel
                        </Button>
                </ModalFooter>
            </form>

            </ModalContent>
          )}
        </Modal>
      </>
    );
  };
  
  export default ModalPreview;
  