import React from "react";
import { render } from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import setupInterceptors from "./services/setupInterceptors";

// import { Auth0Provider } from "@auth0/auth0-react";


// ReactDOM.render(
     
//   <Auth0Provider
//     useCookiesForTransactions={true}
//     domain="dev-86ej135s.us.auth0.com"
//     clientId="Xh9fsHZiv6tCHReOQ2RbicQN0HuM0lIT"
//     redirectUri={window.location.origin, 'https://127.0.0.1:3000/horizon-ui-chakra#/admin/default'}>
//         <App />
//   </Auth0Provider>,
//   document.getElementById("root")
// );

const root = document.getElementById("root");
render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
        root
 );
