import React, {useState, useEffect} from "react";
import { useParams, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Text,
  Box,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  useColorModeValue,
  NumberDecrementStepper,
  useToast
} from "@chakra-ui/react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  DeleteIcon
} from "@chakra-ui/icons";

// You should also import some data for the table
// import { tablesTableData } from "variables/general";
import '../../../../components/pagination/style.scss'
import { useTable, usePagination } from "react-table";
import EditModal from "components/Modal/EditQuestionModal";
import Delayed  from "./Delayed";


function CustomTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  );
  const textColor = useColorModeValue("gray.700", "white");
    
  return (
    <>
      {/* <pre>
        <code style={{ color: 'black' }}>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <Box overflowX={true}>
        <div style={{ overflowY: "scroll", height: "auto" }}>
          <Table {...getTableProps()} color={textColor} variant="striped" colorScheme="brand" size="sm">
            <Thead>
              {headerGroups && headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      // console.log(cell.value);
                      return (
                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Flex justifyContent="space-between" m={4} alignItems="center" style={{ color: 'black' }}>
            <Flex>
              <Tooltip label="First Page">
                <IconButton
                  onClick={() => gotoPage(0)}
                  isDisabled={!canPreviousPage}
                  icon={<ArrowLeftIcon h={3} w={3} />}
                  mr={4}
                />
              </Tooltip>
              <Tooltip label="Previous Page">
                <IconButton
                  onClick={previousPage}
                  isDisabled={!canPreviousPage}
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>
            </Flex>

            <Flex alignItems="center">
              <Text flexShrink="0" mr={8}>
                Page{" "}
                <Text fontWeight="bold" as="span">
                  {pageIndex + 1}
                </Text>{" "}
                of{" "}
                <Text fontWeight="bold" as="span">
                  {pageOptions.length}
                </Text>
              </Text>
              <Text flexShrink="0">Go to page:</Text>{" "}
              <NumberInput
                ml={2}
                mr={8}
                w={28}
                min={1}
                max={pageOptions.length}
                onChange={(value) => {
                  const page = value ? value - 1 : 0;
                  gotoPage(page);
                }}
                defaultValue={pageIndex + 1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select
                w={32}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50, 100, 500, 1000].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label="Next Page">
                <IconButton
                  onClick={nextPage}
                  isDisabled={!canNextPage}
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
              <Tooltip label="Last Page">
                <IconButton
                  onClick={() => gotoPage(pageCount - 1)}
                  isDisabled={!canNextPage}
                  icon={<ArrowRightIcon h={3} w={3} />}
                  ml={4}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </div>
      </Box>
        

    </>
  );
}


function QuestionaireTable(props) {

  const toast = useToast();
  const [tableHeader, setTableHeader] = useState({});
  const [apiData, setApiData] = useState([]);

  const { id } = useParams();

  const { user: currentUser } = useSelector(state => state.auth); //get logged in user - to get AccessToken

  // console.log(currentUser);

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', currentUser.AccessToken);

  const columnsDummy = 
    [
      {
        Header: "community",
        accessor: "Community"
      },
      {
        Header: "name",
        accessor: "Name_of_head_of_hosuehold"
      },
      {
        Header: "occupants(Human)",
        accessor: "Number_of_occupants(humans)"
      },
 
    ];

  const getApiData = async () => {
    try {
      
      const userPosts = fetch('http://159.223.172.47/fetch-questionnaire-response/' + id, {
        method: 'GET',
      headers: myHeaders,
      }).then(response => response.json()).then(responseJSON => {
            // console.log(Object.keys(responseJSON[0]).length);
            var arr = [];
            if(responseJSON.length){

              setApiData(responseJSON);  // set State
              Object.keys(responseJSON[0]).forEach(function(key) {
                arr.push(
                  {
                    Header: key,
                    accessor: key
                  }
                  );
              });
              // console.log(arr);
              setTableHeader(arr);
            } else{
              setTableHeader(columnsDummy);
            }
    })
    
    } catch (err) {
      console.error(err.message);
    }
  };
    
    useEffect(()=>{
      let mounted = true;
      const abortController = new AbortController()
      getApiData();

      const interval=setInterval(()=>{
          getApiData()
          },10000)
            
            
          return()=>{
            mounted = false;
            clearInterval(interval);
        }
    },[])  // includes empty dependency array


  //Update RECORD -- working! NB: Prop - questionGroup MUST be named same as named by caller
  const handleOnSubmitForEdit = (questionGroup) => {
    // console.log(questionGroup);
    const requestOptions = {
      method: 'PUT',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar',
          'x-access-token': currentUser.AccessToken,
      },
      body: JSON.stringify(questionGroup)
    };
    fetch('http://159.223.172.47/question/' + questionGroup.id, requestOptions)
        .then(response => JSON.stringify(response))
        .then(data => {
          data && toast({
            title: "Success!.",
            description: "Record created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true
          });
        })
        
  };

  
  
  //DELETE Record --- 
  function handleDelete(row) {

    if (window.confirm(`Are you sure you want to delete "${row.name}"?`) === true) {

      fetch('http://159.223.172.47/question/' + row.id, {
          method: 'DELETE',
          headers: myHeaders,
        })
        .then(response => JSON.stringify(response))
        .then(data => data)
        // console.log(data);
        data && toast({
          title: "Success!.",
          description: "Record created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true
        });

    } else {

    }
  }

  const columns = React.useMemo(
    () => 
    // [
    
    //       {
    //         Header: "community",
    //         accessor: "Community"
    //       },
    //       {
    //         Header: "name",
    //         accessor: "Name_of_head_of_hosuehold"
    //       },
    //       {
    //         Header: "occupants(Human)",
    //         accessor: "Number_of_occupants(humans)"
    //       },
    //       {
    //         Header: "occupants(Animal)",
    //         accessor: "Number_of_occupants(animal)"
    //       },
    //       {
    //         Header: "House",
    //         accessor: "Type_of_house"
    //       },
    //       {
    //         Header: "description",
    //         accessor: "description"
    //       },
    //       {
    //         Header: "label",
    //         accessor: "label"
    //       },
    //       {
    //         Header: "category_id",
    //         accessor: "category_id"
    //       },
    //       {
    //         Header: "question_order",
    //         accessor: "question_order"
    //       },
    // ]
    tableHeader,
    [tableHeader]
  );


  const data = React.useMemo(() => apiData, [apiData]);
  return (
    <Delayed>
          <CustomTable columns={columns} data={data} />;
    </Delayed>
  )
}

export default QuestionaireTable;

