/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState} from "react";
import { NavLink, useParams } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
// Custom components

// import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
// import { MdOutlineRemoveRedEye } from "react-icons/md";
// import { RiEyeCloseLine } from "react-icons/ri";
// import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons';
import MapComponent from "components/map/";


function Map() {

  const [entryPage, setEntryPage] = useState(false);
  

  //Submit the form  -- CREATE RECORD --- 
  const handleOnSubmit = (questionCat) => {
    // console.log(questionCat);
    
    const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar'
      },
      body: JSON.stringify(questionCat)
    };
    fetch('/questionnaires', requestOptions)
        .then(response => JSON.stringify(response))
        .then(data => {
          toast({
            title: "Success!.",
            description: "Record updated successfully.",
            status: "success",
            duration: 9000,
            isClosable: true
          });
        });

        // // WORKING!!!
    // fetch('/questionnaires')
    //   .then(resp => {
    //     console.log(resp);
    //     console.log('======success=======');
    //   })
    //   .catch(err => {
    //     console.log('======failure=======');
    //     console.log(err);
    //   });
    
  };

  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Box bg="white" w="100%" p={4} color="white">

      {/* <MapChart /> */}
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
            
      </Flex>

      <MapComponent />
    </Box>
  );
}

export default Map;
