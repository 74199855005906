/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState} from "react";
import { NavLink, Redirect, useHistory  } from "react-router-dom";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import Login from './login';
import SignupForm from './signupForm';
import MapChart from "../../../components/map/MapChart";

// const entryPage = false;



function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const toast = useToast();
  

  const [entryPage, setEntryPage] = useState(false);
  let history = useHistory();

  function doSetPage(){
      return setEntryPage(!entryPage);
  }

    //Submit the form  -- SIGN UP --- 
    const handleOnSubmit = (signUpForm) => {
      console.log(signUpForm);
      
      const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'mode': 'no-cors'
        },
        body: JSON.stringify(signUpForm)
      };
      fetch('http://159.223.172.47/signup', requestOptions)
          .then(response => {
            response.ok ? history.push("/auth") : toast({
              title: "Error!.",
              description: "Record created successfully.",
              status: "warning",
              duration: 5000,
              isClosable: true
            });
          })
            //reload();
          // });
          
    };


     //Submit the form  -- SIGN IN --- 
     const handleOnSubmitSignIn = (signUpForm) => {
      const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'mode': 'no-cors'
        },
        body: JSON.stringify(signUpForm)
      };
      fetch('http://159.223.172.47/login', requestOptions)
          .then(response => {
            response.ok ? history.push("/admin/") : toast({
              title: "Error!.",
              description: "Record created successfully.",
              status: "warning",
              duration: 5000,
              isClosable: true
            });
          })
          // .then(data => {
          //   // console.log(data);
          //   data ? <Redirect to='/admin/default/' /> : toast({
          //     title: "Error!.",
          //     description: "Record created successfully.",
          //     status: "warning",
          //     duration: 5000,
          //     isClosable: true
          //   });
          // });
    };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {/* <MapChart /> */}
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        // mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>

        {entryPage ? <SignupForm handleOnSubmit= {handleOnSubmit}/> : <Login handleOnSubmitSignIn= {handleOnSubmitSignIn}/>}

        <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              {!entryPage ? 'Not registered yet?' : 'Already have an account?'}
              <NavLink to='#'>
                <Text
                  onClick = {doSetPage}
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  {!entryPage ? 'Create an Account' : 'Back to Log-in'}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      

    </DefaultAuth>
  );
}

export default SignIn;
