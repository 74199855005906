import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import "assets/css/App.css";
import { HashRouter, Route, Switch, Routes, Link, useLocation, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import Question from "views/admin/questionaire/question";
import Response from "views/admin/questionaire/reponnse";
import ResponseMap from "views/admin/questionaire/ResponseMap";
import QuestionOptions from "views/admin/questionaire/questionOption/";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import ProtectedRoute from './views/auth/protected-route';

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

//website resources
import Layout from './layouts/Layout'
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import ContactUs from './components/pages/ContactUs'
// import WebPage from "./views/website/src/index";
import './App.css';


const App = () => {
  
    // const { isLoading, error } = useAuth0();
    // // console.log(error);
    // if (error) {
    //     return <div>Oops... {error.message}</div>;
    // }

    // if (isLoading) {
    //     return <Loading />;
    // }
   

    return (
        <ChakraProvider theme={theme}>
        <React.StrictMode>
          <HashRouter>
            <Switch>
              <Route path={'/auth'} component={AuthLayout} />

                  {/* <Route path={'/'} component={WebPage} /> */}

                <Route path={'/AboutUs'} component={AboutUs}></Route>
                <Route path={'/ContactUs'} component={ContactUs}></Route>
                <Route path={'/home'} component={Home}></Route>

              {/* {showAdminBoard && (
                <Route name="admin" path="/admin/questions" component={AdminLayout}>  
                  <Route name="questionnaire-questions" path="/admin/questions/:id/" component={Question} />
                </Route>
              )} */}

              <Route name="admin" path="/admin/questions" component={AdminLayout}>  
                <Route name="questionnaire-questions" path="/admin/questions/:id/" component={Question} />
              </Route>

              <Route name="admin" path="/admin/response" component={AdminLayout}>  
                <Route name="questionnaire-response" path="/admin/response/:id/" component={Response} />
              </Route>

              <Route name="admin" path="/admin/response-map" component={AdminLayout}>  
                <Route name="questionnaire-response-map" path="/admin/response-map/:id/" component={ResponseMap} />
              </Route>

              <Route name="admin" path="/admin/questions_options" component={AdminLayout}>  
                <Route name="questions_options" path="/admin/questions_options/:id/:questionnaireId" component={QuestionOptions} />
              </Route>

              {/* <ProtectedRoute path={"/admin"} component={AdminLayout} /> */}
              <Route path={"/admin"} component={AdminLayout} />

              <Route path={'/rtl'} component={RTLLayout} />
              <Redirect from='/' to='/Home' />
            </Switch>
          </HashRouter>
        </React.StrictMode>
      </ChakraProvider>
    );
  }
  
  export default App;